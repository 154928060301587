import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import {
	Button, Typography, TextField, IconButton, InputAdornment, Box
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Cancel from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PercentIcon from '@mui/icons-material/Percent';
import SearchIcon from '@mui/icons-material/Search';
import SearchResultsModal from './GoogleSearchModal';

import html2canvas from 'html2canvas';

const StyledGrid = styled(({multiplier=1, ...props }) => (
	<Grid {...props} item xs={multiplier} />
))(({ theme }) => ({
	padding: theme.spacing(0.2),
	[theme.breakpoints.up('md')]: {
		padding: theme.spacing(0.5),
	},
}));

const nameMultiplier = 1.5

const UnclikableButton = styled(Button)(({ theme, color }) => {
	const paletteColor = color == "primary" ? theme.palette.primary : theme.palette.secondary;
	
	return {
	width: '100%',
	height: '100%',
	fontSize: '10px',
	[theme.breakpoints.up('md')]: {
		fontSize: '20px',
	},
	borderRadius: '5px',
	padding: theme.spacing(1.5),
	"&.Mui-disabled.MuiButton-outlined": {
		color: paletteColor.main,
		borderColor: paletteColor.main,
		borderWidth: '2px',
	},
	"&.Mui-disabled.MuiButton-contained": {
		backgroundColor: paletteColor.main,
		color: paletteColor.contrastText,
		boxShadow: theme.shadows[2],
	},
	"&.MuiButtonBase-root": {
		minWidth: '0',
	}
}});

const NameContainer = styled(TextField)(({ theme }) => ({
	width: '100%',
	height: '100%',
	
	input: {
		color: theme.palette.primary.main,
		fontSize: '10px',
		[theme.breakpoints.up('md')]: {
			fontSize: '20px',
		},
		
	},
	"& .MuiInputBase-adornedStart": {
		paddingLeft: '5px',
	},
	"& .MuiInputBase-adornedEnd": {
		paddingRight: 0,
	},
	'& input::-webkit-outer-spin-button': { display: 'none' },
    '& input::-webkit-inner-spin-button': { display: 'none' },
}));

const ClickableButton = styled(Button)(({ theme }) => ({
	width: '100%',
	height: '100%',
	fontSize: '10px',
	[theme.breakpoints.up('md')]: {
		fontSize: '20px',
	},
	borderRadius: '5px',
	transition: 'all 0.2s ease-in-out',
	'&:hover': {
		transform: 'scale(1.02)',
	},
	"&.MuiButtonBase-root": {
		minWidth: '0',
	}
}));

const InputAdornmentStyled = styled(InputAdornment)(({ theme }) => ({
	'& p': {
		color: theme.palette.secondary.main
	}
}));

const startPersons = [{ name: "" }, { name: "" }, { name: "" }, { name: "" }]
const startItems = [{ name: "", price: "", notPay: [] }, { name: "", price: "", notPay: [] }, { name: "", price: "", notPay: [] },
{ name: "", price: "", notPay: [] }, { name: "", price: "", notPay: [] }]

const Caculator = forwardRef((props, ref) => {
	const [store, setStore] = useState('Costoco');
	const [persons, setPersons] = useState(startPersons);
	const [items, setItems] = useState(startItems);
	const [tax, setTax] = useState(0);
	const [taxType, setTaxType] = useState('percentage');
	const [openImageProcessor, setOpenImageProcessor] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	const [modalAnchorEl, setModalAnchorEl] = useState(null);

	const table = useRef(null);

	useImperativeHandle(ref, () => {
		return {
			refresh,
			screenshot,
			setItems,
			setPersons,
			setTax,
			setTaxType,
		}
	}, [])


	// the part of generating values and total money
	const values = [];
	const total = Array(persons.length).fill(0);
	items.forEach((item) => {
		let row = [];
		let numbersPay = persons.length - item.notPay.length;
		for (let i = 0; i < persons.length; i++) {
			if (item.notPay.includes(i)) {
				row.push(0);
			} else {
				let priceToPay = parseFloat(item.price ? item.price : 0) / numbersPay;
				row.push(priceToPay);
				total[i] += priceToPay;
			}
		}
		values.push(row);
	})
	const sum = total.reduce((a, b) => a + b, 0);
	for (let i = 0; i < persons.length; i++) {
		if (taxType === 'percentage') {
			total[i] = (1 + tax / 100) * total[i];
		} else {
			const taxPercentage = sum !== 0 ? tax / sum : 0;
			total[i] = (1 + taxPercentage) * total[i];
		}
	}

	const modifyStore = (e) => {
		setStore(e.target.value);
	}

	const addPerson = () => {
		let newPersons = [...persons]
		newPersons.push({ name: "", total: 0 })
		setPersons(newPersons);
	}
	const editPerson = (index, name) => {
		let newPersons = [...persons];
		newPersons[index].name = name;
		setPersons(newPersons);
	}
	const removePerson = (index) => {
		if (persons.length === 1) {
			alert("Cannot remove the last person");
			return;
		}
		let newPersons = [...persons];
		newPersons.splice(index, 1);
		// remove that person in the notpay item
		let newItems = [...items];
		newItems.forEach((item) => {
			item.notPay = item.notPay.filter((i) => i !== index).map((i) => i > index ? i - 1 : i);
		})
		setPersons(newPersons);
		setItems(newItems);
	}

	const clickClearPersonName = (index) => {
		return () => editPerson(index, "");
	}
	const addItem = () => {
		let newItems = [...items];
		newItems.push({ name: "", price: "", notPay: [] });
		setItems(newItems);
	}
	const editItem = (index, name, price) => {
		let newItems = [...items];
		if (name != null) {
			newItems[index].name = name;
		}
		if (price != null) {
			newItems[index].price = price;
		}
		setItems(newItems);
	}
	const removeItem = (index) => {
		let newItems = [...items];
		newItems.splice(index, 1);
		setItems(newItems);
	}
	const cancelItemForPerson = (pindex, index) => {
		let newItems = [...items];
		let included = newItems[index].notPay.includes(pindex);
		if (included) {
			newItems[index].notPay = newItems[index].notPay.filter(i => i !== pindex)
		} else {
			newItems[index].notPay.push(pindex)
		}
		setItems(newItems);
	}
	const clearName = (index) => {
		return () => editItem(index, "", null);
	}

	// tax handler
	const editTax = (tax) => {
		if (tax === "") {
			tax = 0;
		}
		setTax(tax);
	}

	const round = (num) => {
		if (!num) return 0;
		return Math.round((num + Number.EPSILON) * 100) / 100;
	}

	// button click
	const clickAddPerson = () => {
		return () => addPerson();
	}
	const clickRemovePerson = (index) => {
		return () => removePerson(index);
	}
	const modifyPersonName = (index) => {
		return (e => editPerson(index, e.target.value));
	}
	const clickAddItem = () => {
		return () => addItem();
	}
	const clickRemoveItem = (index) => {
		return () => removeItem(index);
	}
	const modifyItemName = (index) => {
		return (e => editItem(index, e.target.value));
	}
	const modifyItemPrice = (index) => {
		return (e => editItem(index, null, e.target.value));
	}
	const clickPrice = (pindex, index) => {
		return () => cancelItemForPerson(pindex, index);
	}
	const modifyTax = () => {
		return (e => editTax(e.target.value));
	}

	// functions
	const refresh = () => {
		setPersons([{ name: "" }, { name: "" }, { name: "" }, { name: "" }]);
		setItems([{ name: "", price: "", notPay: [] }, { name: "", price: "", notPay: [] }, { name: "", price: "", notPay: [] },
		{ name: "", price: "", notPay: [] }, { name: "", price: "", notPay: [] }]);
		setTax(0);
	}

	const screenshot = () => {
		// Use html2canvas to capture the table as an image
		html2canvas(table.current).then((canvas) => {
			// Create a temporary <a> element to hold the image data
			const a = document.createElement('a');
			a.href = canvas.toDataURL('image/png');
			a.download = 'table.png';

			// Trigger a click event on the <a> element to initiate the download
			a.click();
		});
	}

	// Add tax type handler
	const handleTaxTypeChange = (event, newType) => {
		if (newType !== null) {
			setTaxType(newType);
			setTax(0); // Reset tax when changing type
		}
	};

	// Add this new function to handle search
	const handleSearch = (itemName, event) => {
		if (itemName.trim()) {
			const results = `https://www.bing.com/images/search?q=${encodeURIComponent("Costco " + itemName)}`;
			setSearchResults(results);
			setModalAnchorEl(event.currentTarget);
			setOpenModal(true);
		}
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		setModalAnchorEl(null);
	};

	return (
		<Box>
		<Grid container
			direction="column"
			justifyContent="flex-start"
			sx={{ minHeight: '60vh', margin: { xs: '0px', md: '10px'} }}
			spacing={1}
			ref={table}
			columns={ persons.length + 2 + nameMultiplier + 1}
		>
			{/*first line*/}
			<Grid container justifyContent="center" spacing={1}>
				{/*tab*/}
				<StyledGrid multiplier={nameMultiplier}>
					<UnclikableButton variant='outlined' disabled> <Box sx={{ fontSize: { xs: '10px', md:'20px' } }}> Name </Box> </UnclikableButton>
				</StyledGrid>
				<StyledGrid>
					<UnclikableButton variant='outlined' disabled> <Box sx={{ fontSize: { xs: '10px', md:'20px' } }}> Price </Box> </UnclikableButton>
				</StyledGrid>
				{/*person*/}
				{persons.map((person, index) => <StyledGrid key={`person-${index}`}>
					<NameContainer focused color='primary' placeholder={`Customer ${index}`} value={person.name} onChange={modifyPersonName(index)}
						InputProps={{
							startAdornment: <AccountCircleOutlinedIcon color='primary' sx={{ display: { xs: 'none', md:'block' } }} />,
							endAdornment: <IconButton color='cancel' onClick={clickClearPersonName(index)} title="Clear Name" sx={{display: {xs: 'none', md: 'block'}}}><Cancel /></IconButton>
						}} 
						sx={{
							"&.MuiFormControl-root":{height: "70%"},
						}}>
					</NameContainer>
					<ClickableButton variant="contained" color="error" onClick={clickRemovePerson(index)} sx={{height: "30%"}} title="Remove Person">
						<Cancel />
					</ClickableButton>
				</StyledGrid>
				)}
				<StyledGrid>
					<Button variant="contained" color="primary" onClick={clickAddPerson()} sx={{ height: "100%" }} title="Add Customer">
						<AddIcon />
					</Button>
				</StyledGrid>
			</Grid>
			{/*rows*/}
			{items.map((item, index) => <Grid container item key={`item-${index}`} justifyContent="center" spacing={1}>
				{/*item self*/}
				<StyledGrid multiplier={nameMultiplier}>
					<NameContainer 
						focused 
						color='primary' 
						placeholder={`Item ${index}`} 
						value={item.name} 
						onChange={modifyItemName(index)}
						InputProps={{ 
							startAdornment: (
									<IconButton 
										onClick={(e) => handleSearch(item.name, e)}
										title="Search on Google"
									>
										<SearchIcon color="error" sx={{fontSize: {xs: '15px', md: '25px'}}}/>
									</IconButton>
							),
							endAdornment: (
									<IconButton 
										color='cancel' 
										onClick={clearName(index)} 
										sx={{display: {xs: 'none', md: 'block'}}}
										title="Clear Name"
									>
										<Cancel />
									</IconButton>
							) 
						}}
					>
					</NameContainer>
				</StyledGrid>
				{/*price*/}
				<StyledGrid>
					<NameContainer focused color='primary' label="price" placeholder={"0"} type="number" value={item.price} onChange={modifyItemPrice(index)}
						InputProps={{ startAdornment: <AttachMoneyIcon color='primary' sx={{ display: { xs: 'none', md:'block' } }} /> }}>
					</NameContainer>
				</StyledGrid>
				{persons.map((p, pindex) => <StyledGrid key={`value-${index}-${pindex}`}>
					<ClickableButton variant={item.notPay.includes(pindex) ? "outlined" : "contained"} color='primary'
						onClick={clickPrice(pindex, index)}>
						{round(values?.[index]?.[pindex]).toString()}
					</ClickableButton>
				</StyledGrid>
				)}
				<StyledGrid>
				{/*delete row*/}
				<Button variant="contained" color="error" onClick={clickRemoveItem(index)} sx={{ height: "100%" }} title="Remove Item">
					<Cancel />
				</Button>
				</StyledGrid>
			</Grid>)}
			<Grid container item justifyContent="center" spacing={1} sx={{marginTop: '10px'}}>
				{/*tab*/}
				<StyledGrid multiplier={nameMultiplier} sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center'}}>
					<Button variant="contained" color="primary" onClick={clickAddItem()} sx={{ height: "100%", width: '30%' }} title="Add Item">
						<AddIcon />
					</Button>
					<Typography
						align="center"
						sx={{ fontSize: "2rem", display: { xs: 'none', md:'block' }, width: '50%' }}
						color="primary"
					>
						Total:
					</Typography>
						
				</StyledGrid>
				<StyledGrid>
				<UnclikableButton disabled variant='outlined' color='secondary'>
					${round(total.reduce((a,b) => a + b, 0))}
				</UnclikableButton>
				</StyledGrid>
				{total.map((totalPerPerson, index) => <StyledGrid key={`totalPerPerson-${index}`}>
					<UnclikableButton disabled variant='contained'>
						<AttachMoneyIcon sx={{ display: { xs: 'none', md:'block' } }}/> {round(totalPerPerson).toString()}
					</UnclikableButton>
				</StyledGrid>
				)}
				<StyledGrid >
					<Box sx={{ display: 'flex', minWidth: '60px', position: 'relative' }}>
						<NameContainer 
							focused 
							color='primary' 
							label={"Tax & Extra"} 
							placeholder={"0"} 
							type="number" 
							value={tax ? tax.toString() : ""} 
							onChange={modifyTax()} 
						/>
						{/* Toggle button group for larger screens */}
						<ToggleButtonGroup 
							value={taxType} 
							exclusive 
							onChange={handleTaxTypeChange} 
							size="small" 
							color="primary"
							sx={{ position: {xs: 'absolute'}, right: {xs: '-30px', md: '-80px'}, bottom: {xs: '50px', md: 'auto'}, zIndex: 1000 }}
						>
							<ToggleButton value="percentage" title="Percentage">
								<PercentIcon />
							</ToggleButton>
							<ToggleButton value="amount" title="Amount">
								<AttachMoneyIcon />
							</ToggleButton>
						</ToggleButtonGroup>
					</Box>
				</StyledGrid>
			</Grid>
			
		</Grid>
		<SearchResultsModal 
			open={openModal}
			onClose={handleCloseModal}
			anchorEl={modalAnchorEl}
			searchResults={searchResults}
		/>
		</Box>
	)

});

export default Caculator;
