import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  CircularProgress,
  Typography,
  styled
} from '@mui/material';
import {
  CameraAlt as CameraIcon,
  Upload as UploadIcon,
  Close as CloseIcon,
  Refresh as RefreshIcon
} from '@mui/icons-material';

const Input = styled('input')({
  display: 'none',
});

const server_url = 'https://x11czhbom3.execute-api.us-east-1.amazonaws.com/dev/api/process-receipt';

const ImagePreview = styled(Box)(({ theme }) => ({
  width: '98%',
  height: '500px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: theme.spacing(1),
  position: 'relative',
  '& canvas': {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  }
}));

const ImageProcessor = ({ open, onClose, onProcess, tableRef }) => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [showCamera, setShowCamera] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
        setResult(null);
        setError(null); 
      };
      reader.readAsDataURL(file);
    }
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setShowCamera(true);
      }
    } catch (err) {
      setError('Failed to access camera');
    }
  };

  const capturePhoto = () => {
    if (videoRef.current && canvasRef.current) {
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;
      canvasRef.current.getContext('2d').drawImage(videoRef.current, 0, 0);
      const imageData = canvasRef.current.toDataURL('image/jpeg');
      setImage(imageData);
      setShowCamera(false);
      stopCamera();
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }
    setShowCamera(false);
  };

  const handleClose = () => {
    setImage(null);
    setResult(null);
    setError(null);
    stopCamera();
    onClose();
  };

  const resetState = () => {
    setImage(null);
    setResult(null);
    setError(null);
  };
  // send image to server
  const sendImageToServer = async () => {

    if (!image) {
      setError('No image selected');
      return;
    }

    const payload = {
      image_data: image.split(',')[1],
      image_width: canvasRef.current.width,
      image_height: canvasRef.current.height
    }
    setLoading(true);
    try {
      const response = await fetch(server_url, {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      setResult(result);
      setError(null);
    } catch (error) {
      setResult(null);
      setError(error.message);
    }
    setLoading(false);
  }

  const updateTable = () => {
    if (!tableRef.current) {
      return;
    }
    let items = [];

    let total = 0.0;
    let total_price = 0.0;
    let tax = 0.0

    const matched_pairs = result?.matched_pairs;
    if (matched_pairs) {
      matched_pairs.forEach(pair => {
        const {item, price} = pair;

        if (item.text.includes('TOTAL')) {
          total_price = price.price;
        } else if (item.text.includes('TAX')) {
          tax = price.price;
        } else if (item.text.includes('DEBIT') || 
                  item.text.includes('CREDIT') || 
                  item.text.includes('REBATE') || 
                  item.text.includes('CASH') || 
                  item.text.includes('CHECK') || 
                  item.text.includes('PAID') || 
                  item.text.includes('PAYMENT')) {
          ;
        } else {
          items.push({name: item.text, price: price.price, notPay: []});
        }
      });
    }
    tableRef.current.setItems(items);
    tableRef.current.setTaxType("amount");
    tableRef.current.setTax(tax);

    if (Math.abs(total_price - total) > 0.01) {
      console.log('Total price does not match, please check the receipt');
    }
    handleClose();
  }

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const matched_pairs = result?.matched_pairs;
    if (!matched_pairs && !image) {
      //clear canvas
      canvasRef.current.getContext('2d').clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      return;
    }
    const ctx = canvasRef.current.getContext('2d');
    const img = new Image();
    img.src = image;
    img.onload = () => {
      canvasRef.current.width = img.width;
      canvasRef.current.height = img.height;
      
      ctx.drawImage(img, 0, 0);

      if (!matched_pairs) {
        return;
      }

      matched_pairs.forEach(pair => {
        const {item, price} = pair;
        const {Width : width1, Height : height1, Left : left1, Top : top1} = item?.block?.Geometry?.BoundingBox;
        const {Width : width2, Height : height2, Left : left2, Top : top2} = price?.block?.Geometry?.BoundingBox;
        // Draw item box in blue
        ctx.strokeStyle = 'blue';
        ctx.lineWidth = 2;
        ctx.strokeRect(
          left1 * img.width,
          top1 * img.height, 
          width1 * img.width,
          height1 * img.height
          );
        // Draw price box in red
        ctx.strokeStyle = 'red'; 
        ctx.lineWidth = 2;
        ctx.strokeRect(
          left2 * img.width,
          top2 * img.height,
          width2 * img.width, 
          height2 * img.height
          );
        });
      }
  }, [result, image]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" >
      <DialogTitle>
        Receipt Reader
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ImagePreview>
          {showCamera ? (
            <video
              ref={videoRef}
              autoPlay
              style={{ width: '100%', maxHeight: '300px' }}
            />
          ) : image ? (
            <canvas ref={canvasRef} />
          ) : (
            <Typography color="textSecondary">
              Upload or take a photo of receipt
            </Typography>
          )}
          {loading && (
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgcolor="rgba(0, 0, 0, 0.5)"
            >
              <CircularProgress color="primary" />
            </Box>
          )}
        </ImagePreview>

        {error && (
          <Typography color="error" align="center" gutterBottom>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', p: 2 }}>
        <Box>
          <Input
            accept="image/*"
            id="image-upload"
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
          />
          <label htmlFor="image-upload">
            <Button
              component="span"
              startIcon={<UploadIcon />}
              variant="outlined"
              sx={{ mr: 1 }}
            >
              Upload
            </Button>
          </label>
          {showCamera ? (
            <Button
              onClick={capturePhoto}
              variant="outlined"
              startIcon={<CameraIcon />}
            >
              Capture
            </Button>
          ) : (
            <Button
              onClick={startCamera}
              variant="outlined"
              startIcon={<CameraIcon />}
            >
              Camera
            </Button>
          )}
        </Box>
        <Box>
          {(image || result) && (
            <Button
              onClick={resetState}
              startIcon={<RefreshIcon />}
              sx={{ mr: 1 }}
            >
              Reset
            </Button>
          )}
          {!result && (
            <Button
              onClick={sendImageToServer}
              variant="contained"
            disabled={!image || loading}
          >
              Process
            </Button>
          )}
          {result && (
            <Button
              onClick={updateTable}
              variant="contained"
              color="secondary"
            >
              Update
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ImageProcessor; 