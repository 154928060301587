import React from 'react';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

const modalStyle = {
    position: 'absolute',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 2,
    width: {
        xs: '60%',
        lg: '50%',
    },
    maxHeight: '90vh',
    overflow: 'hidden'
};

const SearchResultsModal = ({ open, onClose, anchorEl, searchResults }) => {
    if (!anchorEl) return null;

    const anchorRect = anchorEl.getBoundingClientRect();
    
    // Calculate initial position
    let modalTop = anchorRect.top;
    const modalLeft = anchorRect.right + 10;

    // Adjust if modal would go off screen
    const viewportHeight = window.innerHeight;
    if (modalTop + 600 > viewportHeight) { // 500 is the iframe height
        modalTop = Math.max(10, viewportHeight - 620); // 520 = iframe height + some padding
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="search-results-modal"
            aria-describedby="shows google image search results"
        >
            <Box sx={{
                ...modalStyle,
                top: modalTop,
                left: modalLeft,
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <iframe 
                            src={searchResults}
                            title="Search Results"
                            width="100%"
                            height="500px"
                            style={{border: 'none'}}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default SearchResultsModal;