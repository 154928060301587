import { ThemeProvider, createTheme } from '@mui/material/styles';

import BillSpliter from './BillSpliter';


import './App.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#003B71',      // Costco deep blue
      light: '#004C8C',     // Lighter blue
      contrastText: '#fff'
    },
    secondary: {
      main: '#00716E',      // Costco yellow
      light: '#00aaa6',     // Lighter yellow
      contrastText: '#fff'
    },
    error: {
      main: '#E31837',      // Darker red for delete/cancel
      light: '#F13651'      // Regular red for hover
    },
    background: {
      default: '#F5F7FA',   // Light blue-gray background
      paper: '#FFFFFF'      // White
    },
    text: {
      primary: '#003B71',   // Blue for main text
      secondary: '#4A4A4A'  // Dark gray for secondary text
    }
  },
});


const App = (props) => {
    return (
        <ThemeProvider theme={theme}>
            <BillSpliter />
          </ThemeProvider>
    )
}

export default App;